import { useContext, useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import { useMediaQuery } from 'react-responsive';

import { UserContext } from "../helpers/userContext";

import Fa from "../helpers/svg";

const Header = () => {
    const [openMenu, setMenu] = useState(false);
    const { user } = useContext(UserContext);

    const isMobile = useMediaQuery({
        query: '(max-width: 768px)'
    });

    const toggleMenu = (e) => {
        if (!isMobile) return;
        
        window.scrollTo({
            top: 0,
            left: 0,
            behavior: "smooth",
        });
        
        let isOpen = openMenu ? false : true;
        menu(isOpen, isOpen ? 'hidden' : 'auto', 420);
    }

    const menu = (isOpen, overflow, delay) => {
        setTimeout(() => document.querySelector('body').style.overflow = overflow, delay);
        setMenu(isOpen);
    }
    const resize = () => menu(false, 'auto', 0);
    const debounceFn = (callback, delay) => {
        let timer;
        return () => {
            if (timer) clearTimeout(timer);
            timer = setTimeout(() => callback(), delay);
        }
    }
    const debounce = debounceFn(resize, 50);
    useEffect(() => {
        window.addEventListener('resize', debounce);
        return () => window.removeEventListener('resize', debounce);
    }, [debounce])

    return (
        <header>
            <Countdown />
            <nav>
                <NavLink to="/" className='title'>TheWinklers</NavLink>
                {user ?
                    <>
                        <button className="menu-button" onClick={toggleMenu}>
                            <Fa svg={openMenu ? 'close' : 'menu'} />
                        </button>
                        <div className={`menu${openMenu ? ' active' : ''}`}>
                            <div className="links" onClick={toggleMenu}>
                                {user.kidslist === 0 && <NavLink to="/christmas/secret_santa">Secret Santa</NavLink>}
                                <NavLink to="/christmas/me">My List</NavLink>
                                {user.kidslist === 0 && <NavLink to="/christmas/kainn">Kainn's List</NavLink>}
                                <NavLink to="/christmas/vala">Vala's List</NavLink>
                            </div>
                            <Profile />
                        </div>
                    </>
                    : ''}
            </nav>
        </header>
    );
}

const Profile = () => {
    const { user, logout } = useContext(UserContext);

    return (
        <div className="profile" to="/logout">
            <button className="link" onClick={logout}>Logout</button>
            <div className="pic"><img src={user ? user.picture_url : ''} alt="profile pic" /></div>
        </div>
    )
}

const Countdown = () => {
    const [count, setCount] = useState({});

    useEffect(() => {
        const year = new Date().getFullYear();
        const until = new Date('12/25/' + year);

        const countdown = setInterval(function () {
            const today = new Date();
            const difference = until.getTime() - today.getTime();

            if (difference <= 1) clearInterval(countdown);

            const newCount = {};

            newCount.days = Math.trunc(difference / (1000 * 3600 * 24));
            newCount.hours = Math.trunc((difference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
            newCount.minutes = Math.trunc((difference % (1000 * 60 * 60)) / (1000 * 60));
            newCount.seconds = Math.floor((difference % (1000 * 60)) / 1000);

            setCount(newCount);
        }, 1000);

        return () => clearInterval(countdown);
    }, []);

    return (
        <div className="countdown">
            <Output {...count} />
        </div>
    );
}

const Output = ({ days, hours, minutes, seconds }) => {
    if (days > 1) return (
        <>
            <span>{`${days} days till Christmas`}</span>
            <span>{` and ${hours} hour${hours ? 's' : ''} ${minutes} minute${minutes > 1 ? 's' : ''} ${seconds} second${seconds > 1 ? 's' : ''}`}</span>
        </>
    )
    else if (days === 1) return (
        <>
            <span>Christmas Eve, almost there!</span>
            <span>{` and ${hours} hour${hours ? 's' : ''} ${minutes} minute${minutes > 1 ? 's' : ''} ${seconds} second${seconds > 1 ? 's' : ''}`}</span>
        </>
    )
    else if (days === 0) return (
        <>
            <span>It's Christmas!</span>
        </>
    )
}

export default Header;