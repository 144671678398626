import { useContext, useEffect, useState } from "react";
import { UserContext } from "../helpers/userContext";

const Welcome = ({ children }) => {
    const [santa, setSanta] = useState('loading');
    const {user} = useContext(UserContext);

    useEffect(() => {
        async function getsanta() {
            const result = await fetch('/api/getsanta')
                .then(response => response.json())
                .catch(error => console.log(error));

            if (result.name) setSanta(result.name);
        }
        if (user) getsanta();
    }, [user]);

    return (
        <div className="center">
            {user && <h3>Hi {user.name}</h3>}
            <h1>{user && !user.kidslist ? 'Your secret santa is' : 'Welcome'}</h1>
            {user &&
                <>
                    <h2>{santa}</h2>
                    <p>Lists will be online shortly</p>
                </>
            }
            {children}
        </div>
    );
};

export default Welcome;