import { useEffect, useState } from "react";
import { Navigate, Outlet, Route, RouterProvider, createBrowserRouter, createRoutesFromElements, redirect, useRouteError } from "react-router-dom";

import './assets/main.min.css';

import Christmas from './pages/christmas';
import Error from "./pages/error";
import Home from "./pages/home";
import Layout from "./templates/layout";

// import { Test } from "./components/temp";

import GButton from "./components/google-button";
import { getJSON } from "./helpers/getJSON";
import { UserContext } from "./helpers/userContext";

const App = () => {
  const [user, setUser] = useState(null);

  useEffect(() => {
    const result = async () => {
      const result = await fetch('/api/authorise')
        .then(response => getJSON(response))
        .catch(error => console.log(error));

      if (result && result !== undefined) {
        localStorage.setItem('auth', true);
        return setUser(result);
      }

      localStorage.setItem('auth', false);
      setUser(null);
    }
    result();
  }, []);

  async function logout() {
    await fetch('/api/logout').then(response => {
      localStorage.setItem('auth', false);
      setUser(null)
    });
  }

  //Set auth on login
  async function handleCredentialResponse(response) {
    const result = await fetch('/api/authenticate', {
      method: 'post',
      body: JSON.stringify(response)
    })
      .then(response => getJSON(response))
      .catch(error => console.log(error));

    if (result && result.name) {
      localStorage.setItem('auth', true);
      setUser(result);
    }
  }

  //Routes
  const router = createBrowserRouter(
    createRoutesFromElements(
      <Route>

        <Route element={<Layout />} errorElement={<ErrorBoundary />}>

          <Route path='/' loader={() => user ? redirect('/christmas/secret_santa') : null} element={<Home>
            {!user && <GButton fn={handleCredentialResponse} />}
          </Home>} />

          {/* <Route path='/gen' element={<Gen />} /> */}

          {/* <Route path='/test' element={<Test />} /> */}

          <Route path='/christmas' element={<Navigate to={user && user.kidslist === 0 ? '/christmas/secret_santa' : '/christmas/me'} />} errorElement={<ErrorBoundary />} />

          <Route element={<Auth />}>
            <Route loader={christmasLoader} path='/christmas/:list' element={<Christmas />} errorElement={<ErrorBoundary />} />
          </Route>

        </Route>

        <Route path='*' element={<Error />} />

      </Route>
    )
  );

  //Loaders
  function christmasLoader({ params }) {
    const okParams = ['secret_santa', 'me', 'kainn', 'vala'];
    if (!okParams.includes(params.list)) return redirect('/404');

    return null;
  }

  function ErrorBoundary() {
    let error = useRouteError();
    return <Error status={500} message={error.toString()} />
  }

  return (
    <UserContext.Provider value={{ user, logout }}>
      <RouterProvider router={router} />
    </UserContext.Provider>
  );
}

export default App;

export const Auth = () => {
  let auth = localStorage.getItem('auth');
  if (auth === 'false') {
    window.location.href = '/';
  }
  return(
    <Outlet />
  )
}