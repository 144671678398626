import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import Wishlist from "../components/christmas/wishlist";
import { getJSON } from "../helpers/getJSON";

const Christmas = () => {
    const [items, setItems] = useState({});
    const params = useParams();

    useEffect(() => {
        document.getElementById('loading').classList.add('active');
        async function getItems() {
            const newItems = await fetch('/api/wishlist/' + params.list)
                .then(response => getJSON(response))
                .catch((error) => console.log(error));

            if (newItems) {
                if (newItems.wishlist && newItems.wishlist.length > 0) {
                    document.getElementById('loading').classList.remove('active');
                    document.getElementById('isItems').innerHTML = '';
                }
                else {
                    document.getElementById('loading').classList.remove('active');
                    document.getElementById('isItems').innerHTML = 'No items have been added yet!';
                }
                setItems(newItems);
            }
        }
        getItems();
    }, [params.list]);

    return (
        <div className={`center christmas ${params.list}`}>
            <Wishlist {...items} />
        </div>
    )
}

export default Christmas;