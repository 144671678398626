import { Link } from "react-router-dom";

const Error = ({ status = 404, message = 'Oh deer! Seems you have found a missing page.' }) => {
    return (
        <main className="center error">
            <h1>{status}</h1>
            <p>{message}</p>
            <Link to="/">Go Home</Link>
            <div className="gif-wrapper">
                <iframe src="https://giphy.com/embed/1VUrU03Nn848M" width="480" height="360" title="gif"></iframe>
            </div>
        </main>
    );
};

export default Error;