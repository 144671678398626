import { useEffect } from "react";

const google = window.google;

const GButton = ({ fn }) => {
    useEffect(() => {
        google.accounts.id.initialize({
            client_id: process.env.REACT_APP_CLIENT_ID,
            callback: fn
        }, [google]);

        google.accounts.id.renderButton(
            document.getElementById("buttonDiv"),
            { theme: "outline", size: "large" }
        );
    });

    return (
        <div id="buttonDiv"></div>
    );
}

export default GButton;