const Footer = () => {
    return (
        <footer>
            <div>
                <div className="dad"></div>
                <div className="tree"></div>
                <div className="snow"></div>
            </div>
        </footer>
    );
}

export default Footer;