import { useParams } from "react-router-dom";
import { useContext, useEffect, useState } from "react";

import Fa from "../../helpers/svg";
import { isEditing } from "../../helpers/contexts";
import { ItemContext } from "../../helpers/itemContext";
import { UserContext } from "../../helpers/userContext";

export function Card({ id, children }) {
    const editing = useContext(isEditing);

    return (
        <div className={`item${editing ? ' editing' : ''}`} id={id}>
            {children}
        </div>
    )
}

export function Form({ save, id, children, owner_id }) {
    const editing = useContext(isEditing);

    return (
        <>
            {!editing ?
                <>
                    {children}
                </>
                :
                <form onSubmit={save}>
                    <input type="hidden" name="item" value={id} />
                    {owner_id && <input type="hidden" name="user" value={owner_id} />}
                    {children}
                </form>
            }
        </>
    )
}

export function Item({ children, onFave, buying, edit, details, item }) {
    const [items, setItems] = useState([]);

    useEffect(() => {
        const itemObj = { edit, details, ...item };
        setItems(itemObj);
    }, [edit, item, details]);

    return (
        <ItemContext.Provider value={items}>
            <Head onFave={onFave} />
            <Info />
            <Picture />
            <Details buying={buying}>
                {children}
            </Details>
        </ItemContext.Provider>
    );
}

export const Head = ({ onFave }) => {
    const item = useContext(ItemContext);
    const editing = useContext(isEditing);

    if (item === undefined) return;

    return (
        <div className="wishlist-head">
            {editing ?
                <>
                    <input type="text" name="name" defaultValue={item.name} placeholder="name of item" required />
                </>
                :
                <>
                    <h3>{item.name}</h3>
                    {item.edit ?
                        <label className="favourite">
                            <input type="checkbox" onClick={onFave} name="favourite" defaultChecked={item.fave ?? false} />
                            <Fa svg="star" />
                        </label>
                        :
                        <>{item.fave === 1 ? <Fa svg="star" /> : ''}</>
                    }

                </>
            }
        </div>
    )
}

export const Info = () => {
    const item = useContext(ItemContext);
    const editing = useContext(isEditing);

    if (item.id === undefined) return;

    let url = item.link ? new URL(item.link) : '';

    return (
        <div className="item-info">
            {editing ?
                <>
                    <input type="url" name="link" defaultValue={item.link} placeholder="store link" />
                    <input type="number" name="price" max="100" step=".01" defaultValue={item.price ?? 0} placeholder="0" />
                </>
                :
                <>
                    <a href={item.link} target="_blank" rel="noreferrer">{url.hostname}</a>
                    <p>${item.price}</p>
                </>
            }
        </div>
    )
}

export const Picture = () => {
    const item = useContext(ItemContext);
    const editing = useContext(isEditing);

    if (item.id === undefined) return;

    async function previewImg(e) {
        const [file] = e.target.files;

        if (file) {
            const img = e.target.closest('.overlay').previousSibling;
            img.src = URL.createObjectURL(file);
        }
    }

    let placeholder = 'https://thewinklers.s3.ap-southeast-2.amazonaws.com/placeholder.jpg';

    return (
        <div className={`image${editing ? ' editing' : ''}`}>
            <img src={item.image_url ?? placeholder} className='preview' alt="preview" />
            {editing && <label className="overlay">
                <span>Replace Image</span>
                <input type="file" name="image" onChange={previewImg} accept="image/png, image/jpeg" />
            </label>}
        </div>
    )
}

export const Details = ({ children, buying }) => {
    const item = useContext(ItemContext);
    const editing = useContext(isEditing);
    const { user } = useContext(UserContext);

    const params = useParams();
    const kidlists = ['kainn', 'vala'];

    if (item.id === undefined || !user) return;

    return (
        <div className="details">
            {!editing ?
                <p>{item.details ?? ''}</p>
                :
                <>
                    <textarea name="details" defaultValue={item.details} placeholder="add details"></textarea>
                </>
            }
            {children}
            <div className="buying">
                {!user.kidslist && kidlists.includes(params.list) ? (!item.buying ? <button onClick={buying}>Shotgun!</button> : <><p>{item.buyer} is buying this</p>{user.name === item.buyer && <button className="close" onClick={buying}><Fa svg='close' /></button>}</>) : ''}
            </div>
        </div>
    )
}

export const ActionBtns = ({ saved, onCancel, onEdit, onDelete }) => {
    return (
        <div className="action-bar">
            <span className="status">{saved}</span>
            <div className="action-btns" >
                <button className="edit" onClick={onEdit}>edit</button>
                <button className="save" type="submit">Save</button>
                <button className="cancel" onClick={onCancel}>Cancel</button>
                <button className="delete danger" onClick={onDelete}>Delete</button>
            </div>
        </div>
    )
}