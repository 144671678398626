export function resize(img, file, formData, sendForm) {
    const newImg = new Image();

    newImg.addEventListener('load', async () => {
        // create canvas for resizing
        const canvas = document.createElement("canvas");
        const ctx = canvas.getContext("2d");

        // resize image using canvas
        const ratio = Math.min(800 / newImg.width, 500 / newImg.height);

        canvas.width = newImg.width * ratio + .5 | 0;
        canvas.height = newImg.height * ratio + .5 | 0;

        ctx.drawImage(newImg, 0, 0, canvas.width, canvas.height);

        // set image preview
        const dataURI = canvas.toDataURL(file.type, 0.8);
        img.src = dataURI;

        // create new file from canvas
        const blob = await new Promise(rs => canvas.toBlob(rs, file.type, 0.8));
        const newFile = new File([blob], file.name, file);

        // replace submitted image with resized
        formData.delete('image');
        formData.append('newimage', newFile);

        // submit
        sendForm(formData);
    })

    newImg.src = URL.createObjectURL(file);
}