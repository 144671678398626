import { Outlet } from "react-router-dom";

import Header from "../components/header";
import Footer from "../components/footer";
import { useEffect, useState } from "react";

const Layout = ({ loggedIn }) => {
    const [size, setSize] = useState([]);

    useEffect(() => {
        const main = document.querySelector('main');

        if (size.length <= 0) setTimeout(() => {setSize([main.clientWidth, main.clientHeight])}, 1000);
        snow(size);

        const handleDebounce = debounce(() => {
            setSize([
                main.clientWidth,
                main.clientHeight,
            ]);
            snow(size);
        }, 100);

        window.addEventListener('resize', handleDebounce);
        return () => window.removeEventListener('resize', handleDebounce);
    }, [size]);

    const debounce = (fn, ms) => {
        let timer;

        return _ => {
            clearTimeout(timer);
            timer = setTimeout(_ => {
                timer = null;
                fn.apply();
            }, ms);
        }
    }

    return (
        <>
            <Header loggedIn={loggedIn} />
            <main>
                <canvas className="snow" id="canvas"></canvas>
                <Outlet />
                <Footer />
            </main>
        </>
    )
}

export default Layout;

const snow = (size) => {
    //canvas init
    var canvas = document.getElementById("canvas");
    var ctx = canvas.getContext("2d");

    //canvas dimensions
    var W = size[0];
    var H = size[1];
    canvas.width = W;
    canvas.height = H;

    //snowflake particles
    var mp = 150; //max particles
    var particles = [];
    for (var i = 0; i < mp; i++) {
        particles.push({
            x: Math.random() * W, //x-coordinate
            y: Math.random() * H, //y-coordinate
            r: Math.random() * W / 300 + 1, //radius
            d: Math.random() * mp //density
        })
    }

    //Lets draw the flakes
    function draw() {
        ctx.clearRect(0, 0, W, H);

        ctx.fillStyle = "rgba(255, 255, 255, 0.8)";
        ctx.beginPath();
        for (var i = 0; i < mp; i++) {
            var p = particles[i];
            ctx.moveTo(p.x, p.y);
            ctx.arc(p.x, p.y, p.r, 0, Math.PI * 2, true);
        }
        ctx.fill();
        update();
    }

    //Function to move the snowflakes
    //angle will be an ongoing incremental flag. Sin and Cos functions will be applied to it to create vertical and horizontal movements of the flakes
    var angle = 0;
    function update() {
        angle += 0.01;
        for (var i = 0; i < mp; i++) {
            var p = particles[i];
            //Updating X and Y coordinates
            //We will add 1 to the cos function to prevent negative values which will lead flakes to move upwards
            //Every particle has its own density which can be used to make the downward movement different for each flake
            //Lets make it more random by adding in the radius
            p.y += Math.cos(angle + p.d) + 1 + p.r / 2;
            p.x += Math.cos(angle + p.d) * 2;

            //Sending flakes back from the top when it exits
            //Lets make it a bit more organic and let flakes enter from the left and right also.
            if (p.x > W + 5 || p.x < -5 || p.y > H) {
                if (i % 3 > 0) //66.67% of the flakes
                {
                    particles[i] = { x: Math.random() * W, y: -10, r: p.r, d: p.d };
                }
                else {
                    //If the flake is exitting from the right
                    if (Math.sin(angle) > 0) {
                        //Enter from the left
                        particles[i] = { x: -5, y: Math.random() * H, r: p.r, d: p.d };
                    }
                    else {
                        //Enter from the right
                        particles[i] = { x: W + 5, y: Math.random() * H, r: p.r, d: p.d };
                    }
                }
            }
        }
    }

    //animation loop
    setInterval(draw, 23);
}