import { useEffect, useState } from "react";

import Info from "./info";
import { Card, Item, Form, ActionBtns } from "./item";
import { resize } from "../../helpers/resize";
import { isEditing } from '../../helpers/contexts'
import Fa from "../../helpers/svg";

const Wishlist = ({ edit, details, wishlist }) => {
    const [theWishlist, setWishlist] = useState([]);
    const [editing, setEditing] = useState(null);

    useEffect(() => {
        if (wishlist) setWishlist(wishlist);
    }, [wishlist]);

    const removeItem = (newWishlist, check) => {
        for (let i = 0; i < newWishlist.length; i++) {
            if (newWishlist[i].id === check) {
                newWishlist.splice(i, 1);
            }
        }
        return newWishlist;
    }

    const replaceItem = (newWishlist, result) => {
        for (let i = 0; i < newWishlist.length; i++) {
            if (newWishlist[i].id === result.id) {
                newWishlist[i] = result;
            }
        }
        setWishlist(newWishlist);
    }

    const saveItem = async (e) => {
        e.preventDefault();

        const form = e.target;
        let formData = new FormData(form);

        form.querySelector('.status').innerText = 'saving';
        form.querySelector('button.save').disabled = 'true';

        const sendForm = async (formData) => {
            const result = await fetch('/api/wishlist', {
                method: 'post',
                body: formData
            }).then(result => result.json());

            if (!result) return;

            let newWishlist = theWishlist.slice();

            if (result.error === undefined) {
                if (editing === 'new') {
                    newWishlist = await removeItem(newWishlist, 'new');
                    setWishlist([result, ...newWishlist]);
                }
                else {
                    replaceItem(theWishlist.slice(), result);
                }
            }
            else {
                console.log(result);
            }

            setEditing(null);
            form.querySelector('button.save').disabled = 'true';
        }

        const file = formData.get('image');
        if (file.size > 0) {
            const img = e.target.querySelector('.preview');
            resize(img, file, formData, sendForm);
        }
        else {
            sendForm(formData);
        }
    }

    const addItem = (e) => {
        if (!document.getElementById('new')) {
            setEditing('new');
            setWishlist([{ id: 'new' }, ...theWishlist]);
        }
    }

    const editItem = (e) => {
        e.preventDefault();
        const id = e.target.closest('.item').id;
        setEditing(id);
    }

    const cancelEdit = (e) => {
        e.preventDefault();
        let newWishlist = theWishlist.slice();
        newWishlist = removeItem(newWishlist, 'new');
        setWishlist(newWishlist);
        setEditing(null);
    }

    const deleteItem = async (e) => {
        const item = e.target.closest('.item');
        const id = item.id;

        if (window.confirm('Delete this item?')) {
            let data = await fetch('/api/list-actions/delete', {
                method: 'post',
                body: id
            }).then(r => r.json());

            if (data.error === undefined) {
                let newWishlist = theWishlist.slice();
                newWishlist = await removeItem(newWishlist, id);
                document.getElementById('isItems').innerHTML = 'No items have been added yet!';
                setWishlist(newWishlist);
                setEditing(null);
            }
            else {
                console.log(data.error);
            }
        }

        e.preventDefault();
    }

    const faveItem = async (e) => {
        const checked = e.currentTarget.checked;
        const itemId = e.currentTarget.closest('.item').id;

        await fetch('/api/list-actions/savefave', {
            method: 'post',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
                id: itemId,
                fave: checked,
            })
        });
    }

    const isBuying = async (e) => {
        const itemId = e.currentTarget.closest('.item').id;

        let result = await fetch('/api/list-actions/buying', {
            method: 'post',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
                id: itemId,
            })
        }).then(e => e.json());

        if (result.error === undefined) {
            replaceItem(theWishlist.slice(), result);
        }
    }

    return (
        <>
            {details && details.name && <Info name={details.name} />}
            <p id="loading" className="loading"><Fa svg='loading' /></p>
            <p id="isItems"></p>
            {/* <p className="error danger">Error</p> */}
            {edit ? <button onClick={addItem}>Add</button> : ''}

            <div className="wishlist" id="wishlist">
                {theWishlist.length > 0 && theWishlist.map((item, index) => {
                    const id = item.id ?? 'new';
                    const owner_id = details.id ?? item.owner_id;
                    const itemEdit = editing && editing === id ? true : false;
                    const isSaved = itemEdit ? 'not saved' : 'saved';

                    return <isEditing.Provider value={itemEdit} key={index}>
                        <Card id={id}>
                            <Form save={saveItem} id={id} owner_id={owner_id} >
                                <Item edit={edit} buying={isBuying} onFave={faveItem} item={item}>
                                    {edit && <ActionBtns
                                        saved={isSaved}
                                        onEdit={editItem}
                                        onCancel={cancelEdit}
                                        onDelete={deleteItem}
                                    />}
                                </Item>
                            </Form>
                        </Card>
                    </isEditing.Provider>
                })}
            </div>
        </>
    )
}

export default Wishlist;